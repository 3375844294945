import React, { useEffect, useState } from "react";
import { LeadProfessionTypeList, leadsStatusLists } from "../../constants";
import ImageTextContent from "../../component/Layout/ImageTextContent";
import leadsGraphicImage from "../../images/LeadsGraphicImage.jpg";
import CommonHeading from "../../component/Common/CommonHeading";
import LeadsColumn from "../../component/Layout/LeadsColumn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import { Form, Modal } from "react-bootstrap";
import Pagination from "../../component/Pagination/Pagination";
import { useForm } from "../../hooks/useForm";
import { jobTypeList, routes } from "../../constants";
import { getLeadsList, updateLead } from "../../store/slice/leadsSlice";
import { deleteIcon } from "../../icons";

const AdminLeadsManagementPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loader);
  const leadsList = useSelector((state) => state.leads.getLeadsList?.data);
  const leadsLoading = useSelector((state) => state.leads.loader);
  const totalCount = useSelector(
    (state) => state.leads.getLeadsList?.totalCount
  );
  const user = useSelector((state) => state.user.user);

  const params = useParams();
  const { page_number } = params;

  const [openFilter, setOpenFilter] = useState(false);

  const initialFValues = {
    show: false,
    leadId: "",
    lead_type: "",
    roles: "",
    lead_status: "",
    leadStatusModal: false,
    activeToArchive: false,
    leadStatusForUpdate: "",
    deleteModal: false,
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    navigate(`${routes.adminleadsmanagement}/page/1`);
    if (+page_number === 1) {
      dispatch(
        getLeadsList({
          limit: 10,
          lead_type: values.lead_type,
          lead_status: values.lead_status,
          roles: values.roles,
        })
      );
    }
  }, [values.lead_type, values.roles, values.lead_status]);

  const onPageChange = (page_number) => {
    dispatch(
      getLeadsList({
        start: page_number,
        limit: 10,
        lead_type: values.lead_type,
        roles: values.roles,
        lead_status: values.lead_status,
      })
    );
  };

  const closeModalHandler = () => {
    setValues({
      ...values,
      leadStatusModal: false,
      deleteModal: false,
    });
  };

  const modalForArchiveActiveHandler = (id, status, hasArchive) => {
    setValues({
      ...values,
      leadStatusModal: true,
      activeToArchive: hasArchive,
      leadStatusForUpdate: status,
      leadId: id,
    });
  };

  const changeLeadStatusHandler = () => {
    const data = {
      lead_status:
        values?.leadStatusForUpdate === "ACTIVE" && !values.activeToArchive
          ? "CANCELLED"
          : values?.leadStatusForUpdate === "ACTIVE" && values.activeToArchive
          ? "ARCHIVED"
          : values?.leadStatusForUpdate === "ARCHIVED" && values.activeToArchive
          ? "ACTIVE"
          : values.leadStatusForUpdate === "PENDING"
          ? "ACTIVE"
          : "",
    };

    dispatch(
      updateLead({
        id: values.leadId,
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getLeadsList({
                limit: 10,
                lead_type: values.lead_type,
                lead_status: values.lead_status,
                roles: values.roles,
              })
            );
            setValues({
              ...values,
              leadStatusModal: false,
              activeToArchive: false,
              leadStatusForUpdate: "",
              leadId: "",
            });
          }
        },
      })
    );
  };

  const showLeadDeleteModal = (id) => {
    setValues({
      ...values,
      leadId: id,
      deleteModal: true,
    });
  };

  const deleteLeadHandler = () => {
    const data = {
      delete_status: "1",
    };
    dispatch(
      updateLead({
        id: values.leadId,
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getLeadsList({
                limit: 10,
                lead_type: values.lead_type,
                lead_status: values.lead_status,
                roles: values.roles,
              })
            );
            setValues({
              ...values,
              deleteModal: false,
              leadId: "",
            });
          }
        },
      })
    );
  };

  const openPostLeadHandler = () => {
    navigate(routes.admineditleadmanagement);
  };

  return (
    <>
      {(loading || leadsLoading) && <Loader />}
      <main className="zu_all_page_main_content">
        <div className="zu_leads_page">
          <section className="zu_leads_section">
            <div className="zu_leads_content">
              <ImageTextContent
                imageSrc="https://www.shootzu.com/static/crewdash.jpg"
                heading="Leads"
              />
            </div>
          </section>
          <section className="zu_leads_table_section">
            <div
              className={`d-lg-none zu_common_job_heading ${
                openFilter && "zu_common_job_heading_filter_open"
              }`}
            >
              <CommonHeading heading="Leads" />
              <button
                type="button"
                className="zl_common_job_show_filter"
                onClick={() => setOpenFilter(!openFilter)}
              >
                {openFilter ? "Hide Filters" : "Show Filters"}
              </button>
            </div>
            <div className="zu_manage_job_content">
              <div className="zu_manage_job_filter_row">
                <div
                  className={`zu_common_filter_input_row ${
                    openFilter && "zu_common_filter_open"
                  }`}
                >
                  <div className="zu_common_filter_input_one ">
                    <div className="zu_common_select_box">
                      <Form.Select
                        onChange={handleInputChange}
                        id="lead_type"
                        className="zu_common_filter_input_box"
                        name="lead_type"
                      >
                        <option disabled selected>
                          Lead Type
                        </option>
                        <option value="">All</option>

                        {jobTypeList.map((jobType, i) => {
                          return (
                            <option
                              key={i}
                              selected={values?.lead_type === jobType.labelText}
                              value={jobType.labelText}
                            >
                              {jobType.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </div>
                  <div className="zu_common_filter_input_two">
                    <div className="zu_common_select_box">
                      <Form.Select
                        onChange={handleInputChange}
                        id="roles"
                        className="zu_common_filter_input_box"
                        name="roles"
                      >
                        <option disabled selected>
                          Profession Type
                        </option>
                        <option value="">All</option>

                        {LeadProfessionTypeList.map((workType, i) => {
                          return (
                            <option
                              key={i}
                              selected={values?.roles === workType.labelText}
                              value={workType.labelText}
                            >
                              {workType.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="zu_common_filter_input_two">
                    <div className="zu_common_select_box">
                      <Form.Select
                        onChange={handleInputChange}
                        id="lead_status"
                        className="zu_common_filter_input_box"
                        name="lead_status"
                      >
                        <option disabled selected>
                          Lead Status
                        </option>
                        <option value="">All</option>

                        {leadsStatusLists.map((workType, i) => {
                          return (
                            <option
                              key={i}
                              selected={
                                values?.lead_status === workType.labelText
                              }
                              value={workType.labelText}
                            >
                              {workType.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </div>
                </div>
                <button
                  onClick={openPostLeadHandler}
                  className="zu_common_btn zu_btn_lighter_green"
                >
                  post new Lead
                </button>
              </div>
            </div>
            <div className="zu_leads_data_content">
              <div className="zu_leads_data_row">
                {leadsList?.length === 0 ? (
                  <h1 className="zu_no_jobs_found_content">No Leads Found</h1>
                ) : (
                  leadsList?.map((lead, i) => {
                    return (
                      <LeadsColumn
                        key={lead.id}
                        lead={lead}
                        modalForArchiveActiveHandler={
                          modalForArchiveActiveHandler
                        }
                        showLeadDeleteModal={showLeadDeleteModal}
                      />
                    );
                  })
                )}
              </div>
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            </div>
          </section>
        </div>
      </main>
      <Modal
        show={values.leadStatusModal}
        onHide={closeModalHandler}
        centered
        className="zu_common_text_modal zu_decline_offer_modal"
      >
        <div className="zu_common_modal zu_common_center_modal">
          <div className="zu_common_modal_content">
            <h2 className="zu_common_modal_heading">Are you sure ?</h2>
            <p className="zu_common_modal_paragraph">
              You want to{" "}
              {!values.activeToArchive &&
              values?.leadStatusForUpdate === "ACTIVE"
                ? "Cancel"
                : values.activeToArchive &&
                  values?.leadStatusForUpdate === "ARCHIVED"
                ? "Active"
                : values.activeToArchive &&
                  values?.leadStatusForUpdate === "ACTIVE"
                ? "Archive"
                : "Approve"}{" "}
              this Lead!!
            </p>
            <div className="zu_modal_common_btn_row">
              <button
                type="button"
                className="zu_common_btn zu_btn_gray zu_decline_offer_back_btn"
                onClick={closeModalHandler}
              >
                GO BACK
              </button>
              <button
                type="button"
                className={`zu_common_btn ${
                  !values.activeToArchive &&
                  values.leadStatusForUpdate === "ACTIVE"
                    ? "zu_btn_pending_red"
                    : values.activeToArchive &&
                      values.leadStatusForUpdate === "ARCHIVED"
                    ? "zu_btn_progress_orange"
                    : values.activeToArchive &&
                      values.leadStatusForUpdate === "ACTIVE"
                    ? "zu_btn_progress_orange"
                    : ""
                }`}
                onClick={changeLeadStatusHandler}
              >
                {!values.activeToArchive &&
                values.leadStatusForUpdate === "ACTIVE"
                  ? "Cancel Lead"
                  : values.activeToArchive &&
                    values.leadStatusForUpdate === "ARCHIVED"
                  ? "Active Lead"
                  : values.activeToArchive &&
                    values.leadStatusForUpdate === "ACTIVE"
                  ? "Archive Lead"
                  : values.leadStatusForUpdate === "PENDING"
                  ? "Approve Lead"
                  : ""}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={values.deleteModal}
        onHide={closeModalHandler}
        centered
        className="zu_common_text_modal zu_decline_offer_modal"
      >
        <div className="zu_common_modal zu_common_center_modal">
          <div className="zu_common_modal_content">
            <div className="delete_container">{deleteIcon}</div>
            <h2 className="zu_common_modal_heading">Delete Lead</h2>
            <p className="zu_common_modal_paragraph">
              Are you sure you want to delete this Lead?
            </p>
            <div className="zu_modal_common_btn_row">
              <button
                onClick={() => closeModalHandler()}
                type="button"
                className="zu_common_btn zu_btn_gray"
              >
                Cancel
              </button>
              <button
                onClick={() => deleteLeadHandler()}
                type="button"
                className="zu_common_btn zu_danger_btn"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdminLeadsManagementPage;
