import React, { useEffect, useState } from "react";
import ImageTextContent from "../../component/Layout/ImageTextContent";
import leadsGraphicImage from "../../images/LeadsGraphicImage.jpg";
import CommonHeading from "../../component/Common/CommonHeading";
import { locationFillIcon } from "../../icons";
import LeadsColumn from "../../component/Layout/LeadsColumn";
import { Modal } from "react-bootstrap";
import { useForm } from "../../hooks/useForm";
import { userCardList } from "../../store/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Common/Loader";
import PurchaseLeadModal from "../../component/Layout/PurchaseLeadModal";
import { getLeadsList, purchaseLead } from "../../store/slice/leadsSlice";
import { useRef } from "react";
import {
  jobTypeList,
  LeadProfessionTypeList,
  marks,
  routes,
} from "../../constants";
import AutoComplete from "../../component/Common/AutoComplete";
import { Form } from "react-bootstrap";
import { CustomSlider } from "../../helpers/commonFunction";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../component/Pagination/Pagination";

const LeadsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loader);
  const leadsList = useSelector((state) => state.leads.getLeadsList.data);
  const leadsLoading = useSelector((state) => state.leads.loader);
  const totalCount = useSelector(
    (state) => state.leads.getLeadsList?.totalCount
  );

  const ref = useRef();
  const params = useParams();
  const { page_number } = params;

  const [openFilter, setOpenFilter] = useState(false);

  const initialFValues = {
    show: false,
    leadsModal: false,
    purchaseDoneModal: false,
    cardData: [],
    creditCardId: "",
    cardNumber: "",
    cardNumberErr: "",
    expiry: "",
    expiryErr: "",
    cvc: "",
    cvcErr: "",
    cardDataErr: "",
    leadId: "",
    cardBlinkClass: false,
    cardBlinkClassTwo: false,
    lead_type: "",
    location: "",
    distance: 100,
    roles: "",
    leadsData: "0",
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    dispatch(
      getLeadsList({
        limit: values.leadsData === "0" && 10,
        lead_type: values.lead_type,
        location: values.location,
        km: values.distance,
        roles: values.roles,
        my_leads: values.leadsData,
        lead_status: "",
      })
    );
  }, [
    dispatch,
    values.lead_type,
    values.location,
    values.distance,
    values.roles,
    values.leadsData,
  ]);

  useEffect(() => {
    navigate(`${routes.leads}/page/1`);
    if (+page_number === 1)
      dispatch(
        getLeadsList({
          limit: values.leadsData === "0" && 10,
          lead_type: values.lead_type,
          location: values.location,
          km: values.distance,
          roles: values.roles,
          my_leads: values.leadsData,
          lead_status: "",
        })
      );
  }, [
    dispatch,
    values.lead_type,
    values.location,
    values.distance,
    values.roles,
    values.leadsData,
  ]);

  const closeLeadModalHandler = () => {
    setValues({
      ...values,
      leadsModal: false,
      creditCardIdErr: "",
      creditCardId: "",
      cardNumber: "",
      cardNumberErr: "",
      expiry: "",
      expiryErr: "",
      cvc: "",
      cvcErr: "",
      cardDataErr: "",
    });
  };

  const showLeadModalHandler = (id) => {
    dispatch(
      userCardList({
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setValues({
              ...values,
              leadsModal: true,
              cardData: res,
              creditCardId: "",
              leadId: id,
            });
          }
        },
      })
    );
  };

  const handleCardRadio = (id) => {
    setValues({
      ...values,
      cardNumberErr: "",
      expiryErr: "",
      cvcErr: "",
      cardDataErr: "",
      creditCardIdErr: "",
      creditCardId: id,
    });
  };

  const purchaseLeadHandler = (e) => {
    e.preventDefault();
    if (values?.cardData?.length === 0) {
      if (ref.current === true) {
        setValues({
          ...values,
          cardBlinkClass: !values.cardBlinkClass,
          cardBlinkClassTwo: !values.cardBlinkClassTwo,
        });
      } else {
        setValues({
          ...values,
          cardBlinkClassTwo: true,
        });
      }
      ref.current = true;
    } else if (!values.creditCardId) {
      setValues({
        ...values,
        creditCardIdErr: "You have to select atleast one card!",
      });
    } else {
      const data = {
        customer_card_id: values.creditCardId,
        lead_id: values.leadId,
      };
      dispatch(
        purchaseLead({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              dispatch(
                getLeadsList({
                  lead_type: values.lead_type,
                  location: values.location,
                  km: values.distance,
                  roles: values.roles,
                })
              );
              setValues({
                ...values,
                leadsModal: false,
                purchaseDoneModal: true,
              });
            }
          },
        })
      );
    }
  };

  const closePurchaseDoneHandler = () => {
    setValues({
      ...values,
      purchaseDoneModal: false,
      leadsData: "1",
    });
  };

  const locationChangeHandler = (e) => {
    setValues({ ...values, location: "" });
  };

  function valuetext(value) {
    return value;
  }

  function valueTextSlider(e, value) {
    setValues({ ...values, distance: value });
  }

  const onPageChange = (page_number) => {
    dispatch(
      getLeadsList({
        start: page_number,
        limit: 10,
        lead_type: values.lead_type,
        location: values.location,
        km: values.distance,
        roles: values.roles,
      })
    );
  };

  const myAllLeadsList = [
    {
      id: 1,
      labelText: "0",
      label: "All Leads",
    },
    {
      id: 2,
      labelText: "1",
      label: "My Leads",
    },
  ];

  const LeadsDataChangeHandler = (e) => {
    setValues({
      ...values,
      leadsData: e.target.value,
    });
  };

  return (
    <>
      {(loading || leadsLoading) && <Loader />}
      <main className="zu_all_page_main_content">
        <div className="zu_leads_page">
          <section className="zu_leads_section">
            <div className="zu_leads_content">
              <ImageTextContent
                imageSrc="https://www.shootzu.com/static/crewdash.jpg"
                heading="Leads"
              />
            </div>
          </section>
          <section className="zu_leads_table_section">
            <div className="zu_leads_myleads_all_leads">
              {myAllLeadsList?.map((i) => {
                return (
                  <div
                    key={i.id}
                    className="zu_specialty_label_column zu_select_label_box zu_leads_filter"
                  >
                    <input
                      id={`zu_I_need_label${i.id}`}
                      type="radio"
                      className="d-none"
                      name="leadsData"
                      value={i.labelText}
                      checked={values.leadsData === i.labelText}
                      onChange={LeadsDataChangeHandler}
                    />
                    <label htmlFor={`zu_I_need_label${i.id}`}>{i.label}</label>
                  </div>
                );
              })}
            </div>
            {values.leadsData === "0" && (
              <>
                <div
                  className={`d-lg-none zu_common_job_heading ${
                    openFilter && "zu_common_job_heading_filter_open"
                  }`}
                >
                  <CommonHeading heading="Leads" />
                  <button
                    type="button"
                    className="zl_common_job_show_filter"
                    onClick={() => setOpenFilter(!openFilter)}
                  >
                    {openFilter ? "Hide Filters" : "Show Filters"}
                  </button>
                </div>
                <div className="zu_upcomming_job_content">
                  <div
                    className={`zu_common_filter_input_row ${
                      openFilter && "zu_common_filter_open"
                    }`}
                  >
                    <div className="zu_common_filter_input_one zu_common_select_location_icon">
                      {locationFillIcon}

                      <AutoComplete
                        id="location"
                        name="location"
                        values={values}
                        setValues={setValues}
                        placeholder="Location"
                        handleInputChange={(e) => locationChangeHandler(e)}
                      />
                    </div>
                    <div className="zu_common_filter_input_two">
                      <div className="zu_common_select_box">
                        <Form.Select
                          onChange={handleInputChange}
                          id="lead_type"
                          className="zu_common_filter_input_box"
                          name="lead_type"
                        >
                          <option disabled selected>
                            Lead Type
                          </option>
                          <option value="">All</option>

                          {jobTypeList.map((jobType, i) => {
                            return (
                              <option
                                key={i}
                                selected={
                                  values?.lead_type === jobType.labelText
                                }
                                value={jobType.labelText}
                              >
                                {jobType.label}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="zu_common_filter_input_two">
                      <div className="zu_common_select_box">
                        <Form.Select
                          onChange={handleInputChange}
                          id="roles"
                          className="zu_common_filter_input_box"
                          name="roles"
                        >
                          <option disabled selected>
                            Profession Type
                          </option>
                          <option value="">All</option>

                          {LeadProfessionTypeList.map((workType, i) => {
                            return (
                              <option
                                key={i}
                                selected={values?.roles === workType.labelText}
                                value={workType.labelText}
                              >
                                {workType.label}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="zu_common_filter_input_three">
                      <CustomSlider
                        valueLabelDisplay="auto"
                        aria-label="pretto slider"
                        step={100}
                        defaultValue={100}
                        min={100}
                        marks={marks}
                        max={300}
                        valueLabelFormat={"KM"}
                        value={values.distance}
                        getAriaValueText={valuetext}
                        onChangeCommitted={valueTextSlider}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="zu_leads_data_content">
              <div className="zu_leads_data_row">
                {leadsList?.length === 0 ? (
                  <h1 className="zu_no_jobs_found_content">No Leads Found</h1>
                ) : (
                  leadsList?.map((lead, i) => {
                    return (
                      <LeadsColumn
                        key={lead.id}
                        lead={lead}
                        showLeadModalHandler={showLeadModalHandler}
                        values={values}
                      />
                    );
                  })
                )}
              </div>
            </div>
          </section>
        </div>
        <Pagination totalRecords={totalCount} onPageChange={onPageChange} />
      </main>
      <Modal
        show={values.leadsModal}
        onHide={closeLeadModalHandler}
        centered
        className="zu_common_text_modal"
      >
        <PurchaseLeadModal
          cardData={values.cardData}
          creditCardId={values.creditCardId}
          handleCardRadio={handleCardRadio}
          creditCardIdErr={values.creditCardIdErr}
          closeLeadModalHandler={closeLeadModalHandler}
          purchaseLeadHandler={purchaseLeadHandler}
          setValues={setValues}
          values={values}
        />
      </Modal>
      <Modal
        show={values.purchaseDoneModal}
        onHide={() => closePurchaseDoneHandler()}
        centered
        className="zu_offer_sent_modal"
      >
        <div className="zu_common_modal zu_common_center_modal zu_common_heading_paragraph_modal zu_good_luck_modal">
          <div className="zu_common_modal_content">
            <div className="zu_common_heading_paragraph_modal_text">
              <h2 className="zu_common_modal_heading">Good Luck!</h2>
              <p className="zu_common_modal_paragraph">
                Details of this lead have now been revealed, and you’ll also
                receive an email with the full details.
              </p>
            </div>
            <div className="zu_modal_common_btn_row">
              <button
                type="button"
                className="zu_common_btn zu_btn_gray"
                onClick={closePurchaseDoneHandler}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LeadsPage;
