import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  adminManageJobTableColums,
  CrewImageText,
  RowLabel,
  TableBusinessText,
  TableIdText,
  TableJobDateText,
  tableStyles,
} from "../../constants";
import AdminSidebar from "../../component/Layout/AdminSidebar";
import ImageTextContent from "../../component/Layout/ImageTextContent";
import CommonHeading from "../../component/Common/CommonHeading";
import leadsGraphicImage from "../../images/LeadsGraphicImage.jpg";
import Select from "../../component/Common/Select";
import { checkedGreenIcon } from "../../icons";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  approveJobStatus,
  getjobListbyUser,
} from "../../store/slice/postSlice";
import Pagination from "../../component/Pagination/Pagination";
import { Modal } from "react-bootstrap";
import Loader from "../../component/Common/Loader";
import moment from "moment";

const AdminManageJobsPage = () => {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(false);
  const [approveJobModal, setApproveJobModal] = useState(false);
  let adminManageJobTableColumsData = [];
  let adminPayPendingJobTableColumsData = [];
  const [selectedJobId, setSelectedJobId] = useState("");
  let manageJobs = useSelector((state) => state.post?.getjobListbyUser) || [];
  const totalCount = useSelector((state) => state.post?.totalCount);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const navigate = useNavigate();
  const loading = useSelector((state) => state.post.loader);

  let payPendingJobs = useSelector((state) => state.post?.payPendingJobs) || [];

  useEffect(() => {
    dispatch(
      getjobListbyUser({
        admin: "1",
        limit: "10",
        job_status: selectedFilter,
        search_key: searchedValue,
      })
    );
    dispatch(
      getjobListbyUser({
        payPending: "1",
        admin: "1",
        limit: "1000",
        job_status: "COMPLETED",
        search_key: "",
      })
    );
  }, []);

  function statusLabelcolor(status) {
    let labelColor = {
      NEW: "zu_table_status_new_label",
      WAITING_FOR_RESPONSE: "zu_table_status_primary_label",
      OFFER_ACCEPTED: "zu_table_status_warning_label",
      COMPLETED: "zu_table_status_danger_label",
      DONE: "zu_table_status_success_label",
      CANCELLED: "zu_table_status_cancelled_label",
      POSTED: "zu_table_status_approve__label",
      IN_PROGRESS: "zu_table_status_in_progress__label",
    };
    return labelColor[status];
  }

  function statusLabel(label, applicant) {
    let status = {
      NEW: applicant < 2 ? `${applicant} APPLICANT` : `${applicant} APPLICANTS`,
      WAITING_FOR_RESPONSE: "OFFER PENDING",
      OFFER_ACCEPTED: "BOOKED",
      COMPLETED: "PAY PENDING",
      DONE: "COMPLETED",
      CANCELLED: "CANCELLED",
      POSTED: "APPROVE",
      IN_PROGRESS: "IN PROGRESS",
    };
    return status[label];
  }

  let date;
  manageJobs?.map((job) => {
    date = moment(job.date).utc().format("DD MMM YYYY");
    adminManageJobTableColumsData = [
      ...adminManageJobTableColumsData,
      {
        jobId: <TableIdText text={`${job.id}`} />,
        jobDate: <TableJobDateText text={date} />,
        jobTitle: (
          <div className="element">
            <TableBusinessText text={`${job.title}`} />
            <span className="tooltip">{job.title}</span>
          </div>
        ),
        crewImgText: (
          <CrewImageText
            text={`${job.owner_first_name} ${job.owner_last_name}`}
            imgsrc={job.profile_photo}
          />
        ),
        statusLabel: (
          <RowLabel
            text={statusLabel(job.job_status, job.total_applicant_count)}
            labelColor={statusLabelcolor(job.job_status)}
            btnStyle={job.job_status === "POSTED" && true}
            icon={job.job_status === "COMPLETED" && checkedGreenIcon}
            onClickHandler={() => {
              if (job.job_status === "POSTED") {
                setSelectedJobId(job.id);
                setApproveJobModal(true);
              }
            }}
            onViewClickHandler={() => {
              navigate(`/adminmanagejobsdetail/${job.id}`, {
                state: { job_status: job.job_status },
              });
            }}
          />
        ),
      },
    ];
  });

  payPendingJobs?.map((job) => {
    date = moment(job.date).utc().format("DD MMM YYYY");
    adminPayPendingJobTableColumsData = [
      ...adminPayPendingJobTableColumsData,
      {
        jobId: <TableIdText text={`${job.id}`} />,
        jobDate: <TableJobDateText text={date} />,
        jobTitle: (
          <div className="element">
            <TableBusinessText text={`${job.title}`} />
            <span className="tooltip">{job.title}</span>
          </div>
        ),
        crewImgText: (
          <CrewImageText
            text={`${job.owner_first_name} ${job.owner_last_name}`}
            imgsrc={job.profile_photo}
          />
        ),
        statusLabel: (
          <RowLabel
            text={statusLabel(job.job_status, job.total_applicant_count)}
            labelColor={statusLabelcolor(job.job_status)}
            btnStyle={job.job_status === "POSTED" && true}
            icon={job.job_status === "COMPLETED" && checkedGreenIcon}
            onClickHandler={() => {
              if (job.job_status === "POSTED") {
                setSelectedJobId(job.id);
                setApproveJobModal(true);
              }
            }}
            onViewClickHandler={() => {
              navigate(`/adminmanagejobsdetail/${job.id}`, {
                state: { job_status: job.job_status },
              });
            }}
          />
        ),
      },
    ];
  });

  const onApprovingJob = async () => {
    let data = {
      job_status: "NEW",
    };
    dispatch(
      approveJobStatus({ data, selectedJobId, job_status: selectedFilter })
    );
    setApproveJobModal(false);
  };

  const onPageChange = (page_number) => {
    dispatch(
      getjobListbyUser({
        start: page_number,
        limit: 10,
        waitingForApproval: 0,
        admin: 1,
        job_status: selectedFilter,
        search_key: searchedValue,
      })
    );
  };

  useEffect(() => {
    navigate("/adminmanagejobs/page/1");
    const timer = setTimeout(() => {
      dispatch(
        getjobListbyUser({
          admin: "1",
          limit: "10",
          job_status: selectedFilter,
          search_key: searchedValue,
        })
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [selectedFilter, searchedValue]);

  return (
    <>
      {loading && <Loader />}
      <AdminSidebar />
      <main className="zu_all_page_main_content">
        <div className="zu_admin_manage_job_page">
          <section className="zu_admin_manage_job_banner_section">
            <div className="zu_manage_job_banner_content">
              <ImageTextContent
                imageSrc="https://www.shootzu.com/static/crewdash.jpg"
                heading="Manage Jobs"
              />
            </div>
          </section>
          <section className="zu_manage_job_section">
            <div className="d-lg-none zu_common_job_heading">
              <CommonHeading heading="Manage Jobs" />
            </div>
            <div className="zu_admin_manage_user_table_column">
              <div className="zu_admin_manage_user_table_heading_row">
                <h2 className="zu_admin_manage_user_table_heading">
                  Pay Pending Jobs
                </h2>
              </div>
              <div className="zu_common_data_table zu_admin_manage_job_table">
                <DataTable
                  columns={adminManageJobTableColums}
                  data={adminPayPendingJobTableColumsData}
                  customStyles={tableStyles}
                />
              </div>
            </div>
            <br />
            <br />

            <div
              className={`d-lg-none zu_common_job_heading ${
                openFilter && "zu_common_job_heading_filter_open"
              }`}
            >
              <button
                type="button"
                className="zl_common_job_show_filter"
                onClick={() => setOpenFilter(!openFilter)}
              >
                {openFilter ? "Hide Filters" : "Show Filters"}
              </button>
            </div>
            <div className="zu_admin_manage_user_table_heading_row">
              <div className="zu_admin_manage_user_table_heading_filter">
                <h2 className="zu_admin_manage_user_table_heading">
                  Search / Manage Jobs
                </h2>
                <div className="zu_manage_job_content">
                  <div className="zu_manage_job_filter_row">
                    <div
                      className={`zu_common_filter_input_row ${
                        openFilter && "zu_common_filter_open"
                      }`}
                    >
                      <div className="zu_common_filter_input_one">
                        <Select
                          defaultText="Filter Job Status"
                          value={selectedFilter}
                          onChange={(e) => {
                            setSelectedFilter(e.target.value);
                          }}
                        />
                      </div>
                      <div className="zu_common_filter_input_three">
                        <div className="zu_common_filter_input_box zu_common_input_search_icon">
                          <input
                            type="text"
                            placeholder="Search"
                            value={searchedValue}
                            onChange={(e) => {
                              setSearchedValue(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="zu_common_data_table zu_admin_manage_job_table">
                <DataTable
                  columns={adminManageJobTableColums}
                  data={adminManageJobTableColumsData}
                  customStyles={tableStyles}
                />
                <Pagination
                  totalRecords={totalCount}
                  onPageChange={onPageChange}
                />
              </div>

              {approveJobModal === true && (
                <div className="zu_common_modal zu_common_center_modal zu_purchase_lead_msg_modal">
                  <Modal
                    show={approveJobModal}
                    onHide={() => setApproveJobModal(false)}
                    centered
                    className="zu_purchase_lead_msg_modal"
                  >
                    <div className="zu_common_modal zu_common_center_modal">
                      <div className="zu_common_modal_content">
                        <div className="zu_common_modal_heading_paragraph">
                          <h2 className="zu_common_modal_heading">
                            Job Approval
                          </h2>
                          <p className="zu_common_modal_paragraph">
                            Are you sure you want to approve this job?
                          </p>
                        </div>

                        <div className="zu_modal_common_btn_row">
                          <button
                            type="button"
                            className="zu_common_btn zu_btn_gray zu_send_msg_modal_cancel_btn"
                            onClick={() => {
                              setApproveJobModal(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="zu_common_btn zu_btn_light_green zu_approve_btn"
                            onClick={() => {
                              onApprovingJob();
                            }}
                          >
                            Approve
                          </button>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              )}
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default AdminManageJobsPage;
