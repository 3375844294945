import React, { useEffect } from "react";
import {
  tableStyles,
  invoiceTableColums,
  TableIdText,
  TableJobDateText,
  TableBusinessText,
  CrewImageText,
  DownloadButton,
  ProcessingLabel,
} from "../../constants";

import ImageTextContent from "../../component/Layout/ImageTextContent";

import invoicesGraphicImage from "../../images/InvoicesGraphicImage.jpg";
import CommonHeading from "../../component/Common/CommonHeading";
import DataTable from "react-data-table-component";
import { getInvoiceList } from "../../store/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";
import Pagination from "../../component/Pagination/Pagination";

const InvoicePage = () => {
  const dispatch = useDispatch();

  const invoices = useSelector((state) => state.user.invoices?.data);
  const totalCount = useSelector((state) => state.user.invoices?.totalCount);

  const invoiceTableColumsData =
    invoices &&
    invoices.map((item, i) => {
      return {
        id: item.id,
        projectId: <TableIdText text={item.id} />,
        date: (
          <TableJobDateText
            text={moment(item?.date).utc().format("DD MMM YYYY")}
          />
        ),
        invoice_type: (
          <TableBusinessText text={capitalizeFirstLetter(item?.invoice_type)} />
        ),
        crewImgText: (
          <CrewImageText
            imgsrc={
              item?.invoice_type === "crew"
                ? item?.owner_profile_photo
                : item?.crew_profile_photo
            }
          />
        ),
        invoiceButton:
          item?.job_status === "DONE" ? (
            <DownloadButton jobDetail={item} />
          ) : (
            <ProcessingLabel />
          ),
      };
    });

  useEffect(() => {
    dispatch(getInvoiceList({}));
  }, [dispatch]);

  const onPageChange = (page_number) => {
    dispatch(getInvoiceList({ start: page_number }));
  };

  return (
    <>
      <main className="zu_all_page_main_content">
        <div className="zu_invoice_page">
          <section className="zu_invoice_section">
            <div className="zu_invoice_content">
              <ImageTextContent
                imageSrc="https://www.shootzu.com/static/crewinvoices.jpg"
                heading="Invoices"
              />
            </div>
          </section>
          <section className="zu_invoice_table_section">
            <div className="d-lg-none zu_common_job_heading">
              <CommonHeading heading="Invoices" />
            </div>
            <div className="zu_common_data_table">
              <DataTable
                columns={invoiceTableColums}
                data={invoiceTableColumsData}
                customStyles={tableStyles}
              />
            </div>
          </section>
        </div>
        {totalCount > 0 && (
          <Pagination totalRecords={totalCount} onPageChange={onPageChange} />
        )}
      </main>
    </>
  );
};

export default InvoicePage;
